@import '../../../assets/scss/variables';


.Container {
  margin-bottom: .75rem;
}

.Label {
  display: block;
  width: 100%;
  margin-bottom: 0.25rem;
}


.Input {
  width: 100%;
  border-radius: $input-border-radius;
  border: $input-border;
  padding: .375rem .75rem;
  font-weight: 400;
  line-height: $input-line-height;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  background: $content;

  &:focus {
    outline: none;
    border-color: $primary;
  }
}


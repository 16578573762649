@import '../../../assets/scss/variables';

.Button {
  position: relative;
  color: #FFF; 
  border: none;
  padding: 0.75rem 1.5rem;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  border-radius: .25rem;
}

.Rounded {
  border-radius: 2rem;
}

.Square {
  border-radius: 0;
}

@mixin button($color) {
  background: $color;
  &.Disabled {
    background: lighten($color, 10%);
    cursor: not-allowed;
  }
}

.Primary {
  @include button($primary);
}

.Danger {
  @include button($danger);
}

.Success {
  @include button($success);
}

.Info {
  @include button($info);
}

.Default {
  @include button($dark-gray);
}

.Small {
  padding: 0.5rem 1rem;
}

.Large {
  padding: 1rem 1.75rem;
}

@import "../../../assets/scss/variables";

.Menu {
  display: block;
  position: absolute;
  height: 100vh;
  width: 100%;
  background: white;
  z-index: -1;
  transition: opacity linear 0.3s;
  opacity: 0;

  @include for-desktop-up {
    max-width: $app-max-width;
  }

  &.isOpen {
    z-index: 101;
    opacity: 1;
  }
}

.MobileHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
}

.Toggle {
  height: 3rem;
  vertical-align: middle;
  font-size: 2rem;
  color: $primary;
  cursor: pointer;
  display: flex;
  align-items: center;
}

@mixin menuItemActiveOrHover {
  color: $info;
}

.MenuItemContainer {
  position: relative;
  flex-grow: 1;

  &:hover {
    .Dropdown {
      opacity: 1;
      z-index: 999;
    }

    .MenuIcon {
      transform: rotate(180deg);
    }
  }
}

.MenuItem {
  display: inline-flex;
  height: 2.5rem;
  align-items: center;
  flex-grow: 1;
  cursor: pointer;
  text-decoration: none;
  color: $primary;
  font-weight: bold;
  padding: 0 1rem;
  font-size: $font-size-title;

  > .MenuIcon {
    margin: 0 0 0 .5rem;
    font-size: .75rem;
    transition: transform 0.3s;
  }

  &:hover {
    @include menuItemActiveOrHover;
  }
}

.Dropdown {
  opacity: 0;
  position: absolute;
  width: 100%;
  background: $content;
  border: 1px solid $light-gray;
  z-index: -1;
  transition: 0.3s;
}

.MenuItemActive {
  @include menuItemActiveOrHover;
}

@import './assets/scss/variables';

.Container {
   background: $background;
   margin: 0 auto;
   height: 100%;
   position: relative;

   @include for-desktop-up {
      max-width: $app-max-width;
   }
}


@import '../../assets/scss/variables';

.Container {
  display: flex;
  flex-direction: column;
  height: calc(100% - 4rem);
}

.ListContainer {
  overflow-y: auto;
  flex: 1;
}

.ButtonContainer {
  margin: 1rem;
}

.Button {
  color: #FFF;
  background: $info;
  border: 0;
  width: 100%;
  padding: 1rem 0;
  cursor: pointer;
  border-radius: 2rem;
  font-weight: bold;
  outline: none;
}

.ContactRequest {
  background: $info;
}

.OpenContactRequest {
  background: $primary;
}

.ButtonIcon {
  font-size: 2rem;
  margin-bottom: .5rem;
}

@import '../../../assets/scss/variables';

.Container {
  display: flex;
  align-items: center;
  padding: 1rem;
  margin: 1rem 0;
  border-radius: 4px;
  color: #FFF;
}


@mixin alert-color($color) {
  background: $color;
  border: 1px solid darken($color: $color, $amount: 10%);
}


.Success {
  @include alert-color($success);
}

.Danger {
  @include alert-color($danger);
}

.Primary {
  @include alert-color($primary);
}
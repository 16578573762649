@import '../../assets/scss/variables';

.LanguageContainer {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -.5rem;
}


.LanguageItem {
  background: $light-gray;
  padding: .5rem;
  margin: .5rem;
  cursor: pointer;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid $dark-gray;
  border-radius: .25rem;
  width: 4rem;

  &.Selected {
    background: $primary;
    color: #FFF;
  }

  &:hover {
    background: $dark-gray;
  }


  img {
    max-width: 100%;
  }

  p {
    margin: 0;
    font-weight: bold;
  }
}

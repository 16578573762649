@import '../../../assets/scss/variables';


.Container {
  margin-bottom: .75rem;
}

.Label {
  display: block;
  width: 100%;
  margin-bottom: 0.25rem;
}

.Icon {
  font-size: 2rem;
  cursor: pointer;
  color: $dark-gray;
}

.Checked {
  color: $primary;
}
@import "../../../assets/scss/variables";

.Title {
  color: $primary;
  margin: 0;
}

.Container {
  transition: transform 0.3s;
  transform: translateY(100vh);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 100;
  height: 100%;
  width: 100%;
}

@media print {
  .Container {
    display: none;
  }
}

.CloseModal {
  cursor: pointer;
}


.ContainerOpen {
  transition-delay: 0.3s;
  transform: translateY(0);
}

.Content {
  background: #fff;
  border-radius: 4px;
  z-index: 101;
  padding: 1rem;
  flex-direction: column;
  overflow-y: auto;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  max-height: 100%;
  width: calc( #{$app-max-width} - 2rem );
  max-width: calc( 100% - 2rem );
  margin: 0 1rem;

  &.NoScroll {
    overflow-y: inherit;
  }
}

.Header {
  flex-grow: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0 1rem 0;
}

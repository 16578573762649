@import "../../assets/scss/variables";

.Container {
  padding: 0 1rem;
  margin-bottom: 1rem;
}

@media print {
  .Container {
    display: none;
  }
}

.Wrapper {
  display: flex;
  align-items: center;
  height: 3rem;
}

.ToggleMenu {
  cursor: pointer;
}

.Icon {
  color: $info;
  font-size: 1rem;
}

.Title {
  color: $info;
  font-weight: bold;
  margin: 0 auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


.IconButtons {
  color: $info;
  font-size: 1rem;

  svg {
    cursor: pointer;

    &:not(:last-child) {
      margin-right: .5rem;
    }
  }
}

@import "./assets/scss/variables";
@import "../node_modules/normalize.css/normalize.css";

@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display:ital,wght@0,400;0,500;0,700;1,400&display=swap');


* {
  box-sizing: border-box;
}


html, #root {
  height: 100%;
}

body {
  background: #FFF;
  height: 100%;
  font-family: 'Red Hat Display', Tahoma;
  position: fixed;
  overflow-y: auto;
  width: 100%;
  -webkit-overflow-scrolling: touch;
}

.text-danger {
  color: $danger;
}

.text-success {
  color: $success;
}

.bold {
  font-weight: bold;
}

.nowrap {
  white-space: nowrap;
}

pre {
  font-family: inherit;
  font-size: 1rem;
  margin: 0;
  white-space: pre-wrap;
}

.no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@media print {
  .print-hidden {
    display: none;
  }
}
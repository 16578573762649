@import "../../../assets/scss/variables";

.SizeLarge {
  font-size: 3rem;
}

.SizeMedium {
  font-size: 2rem;
}

.SizeSmall {
  font-size: 1rem;
}

.ColorContent {
  color: $content;
}

.ColorPrimary {
  color: $primary;
}

.Center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.CenterAbsolute {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: calc(100% - 4rem);
  z-index: 999;
  position: absolute;
  background: $content;
}

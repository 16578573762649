@import '../../assets/scss/variables';

.Container {
  margin: 1rem;
  border: 1px solid $dark-gray;
  padding: 1rem;
  background: $info-item-bg;
}

.Clickable {
  cursor: pointer;
}

.Title {
  color: $primary;
  margin: 0;
}

.Date {
  margin-top: .5rem;
  color: $text-muted
}


.Image {
  width: 100%;
}

.Description {
  @include editorContent;
}
@import 'customer';

$app-max-width: 40rem;
$header: #FFF;

$text-muted: #666;

$content: #FFF;

$background: #FFF;

$dark-gray: #b7b7b7;
$light-gray: #DDD;

$table-hover: #f5f5f5;

$input-disabled-bg: #e9ecef;

$text-color: #000;

$font-size-title: 1.25rem;

$input-border: 1px solid $dark-gray;
$input-vertical-padding: .375rem;
$input-horizontal-padding: .25rem;
$input-line-height: 1.5;
$input-border-radius: 4px;

$info-item-bg: #FFF;


@mixin for-phone-only {
  @media (max-width: 599px) { @content; }
}

@mixin for-tablet-portrait-up {
  @media (min-width: 600px) { @content; }
}

@mixin for-tablet-landscape-up {
  @media (min-width: 900px) { @content; }
}

@mixin for-desktop-up {
  @media (min-width: 1200px) { @content; }
}

@mixin for-desktop-down {
  @media (max-width: 1199px) { @content; }
}

@mixin for-big-desktop-up {
  @media (min-width: 1800px) { @content; }
}

@mixin editorContent {
  h1 {
    color: $primary;
    margin: 0;
    font-size: 1.17rem;
  }

  h2 {
    font-size: 1rem;
    color: $primary;
  }

  p {

  }

  img {
    cursor: default !important;
    max-width: 100%;
  }
}